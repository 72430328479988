import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import { IoMdArrowForward } from 'react-icons/io';

export default function CardVertical({ item, linkTo, index }) {
	return (
		<div>
			<div className='card-container' key={item.ID}>
				{item.Imagem !== undefined && item.Imagem !== null ? (
					<figure className='card-image' style={{ backgroundImage: `url(${item.Imagem.Url})` }}></figure>
				) : (
					<figure className={`card-image noticia_${index}`}></figure>
				)}

				{item.Title !== undefined ? (
					<h2 className='card-title'>{item.Title}</h2>
				) : (
					<h2 className='card-title'>[no-title]</h2>
				)}

				<Link to={linkTo} className='card-link'>
					Ver noticia <IoMdArrowForward size={24} />
				</Link>
			</div>
		</div>
	);
}
