import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

export default function QueDizLei() {
	const quedizlei = [
		{
			Title: 'Legislação',
			Texto: 'Constituição Federal, Leis, Convenções e Recomendações',
		},
		{
			Title: 'Jurisprudência',
			Texto: 'Decisões judiciais e tendências de interpretações',
		},
		{
			Title: 'Proposição Legislativa',
			Texto: 'Projetos de Lei e de Emendas Constitucionais (PEC)',
		},
	];

	return (
		<div className='quedizlei-container' id=''>
			{quedizlei.map((lei, index) => (
				<Link
					to={`/oquedizalei/${lei.Title.toLowerCase()
						.normalize('NFD')
						.replace(/[\u0300-\u036f/' '/]/g, '')}`}
					className='quedizlei-card'
					key={index}
				>
					<div className='title'>{lei.Title}</div>
					<div className='text'>{lei.Texto}</div>
				</Link>
			))}
		</div>
	);
}
