import React, { useState, useEffect, useRef, useCallback } from 'react';
import './styles.scss';
import { getData } from '../../services/api';
import PostsList from '../../components/PostList';
import { SkeletonPostList } from '../../components/Skeleton';
import PlaceholderImg from '../../images/placeholder.png';
import { RiDownload2Line } from 'react-icons/ri';
import Modal from '../../components/Modal';
import useModal from '../../hooks/useModal';

export default function EtapasDaGreve() {
	const { isShowing, toggle } = useModal();
	const [type, setType] = useState([]);
	const [modeloID, setModeloID] = useState([]);

	const [load, setLoad] = useState(false);
	const [hasMore, setHasMore] = useState([]);
	const [targetPage, setTargetPage] = useState([]);
	const [query, setQuery] = useState([]);

	const [allTopics, setAllTopics] = useState([]);
	const [allModels, setAllModels] = useState([]);
	const [allItems, setAllItems] = useState([]);
	const [allNews, setAllNews] = useState([]);

	const [phases, setPhases] = useState([]);
	const [items, setItems] = useState([]);
	const [listGUID, setListGUID] = useState([]);

	const observer = useRef();
	const lastItem = useCallback(
		node => {
			if (load) return;

			if (observer.current) observer.current.disconnect();

			observer.current = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting && hasMore) {
					setLoad(true);
					infinitePagination(query, listGUID);
				}
			});

			if (node) observer.current.observe(node);
		},

		[load, hasMore, query, listGUID]
	);

	const infinitePagination = (queryValue, listID) => {
		const itemsContainer = document.querySelector('.posts-results');
		const idOfLastItem =
			itemsContainer !== null && itemsContainer !== undefined && itemsContainer.hasChildNodes()
				? itemsContainer.lastElementChild.id
				: 0;

		const paginationWithoutQuery = async () => {
			const itemsFilter = `items?&$skiptoken=Paged=TRUE%26p_ID=${idOfLastItem}&$top=10`;
			const res = await getData(listID, itemsFilter);

			setItems(itensAnteriores => {
				return [...new Set([...itensAnteriores, ...res.data])];
			});

			setHasMore(res.data.length > 0);

			setLoad(false);
		};

		const paginationWithQuery = async query => {
			const termo = query.length > 0 ? query : '';
			const itemsFilter = `items?&$skiptoken=Paged=TRUE%26p_ID=${idOfLastItem}&${query}`;
			const res = await getData(listID, itemsFilter);

			setItems(itensAnteriores => {
				return [...new Set([...itensAnteriores, ...res.data])];
			});

			setHasMore(termo !== '' ? res.data.length > 0 : res.data.length > 0);

			setLoad(false);
		};

		queryValue.length !== 0 ? paginationWithQuery(queryValue) : paginationWithoutQuery();
	};

	//getting informations to create the cards and lists like topics, news, etc
	useEffect(() => {
		const getPhases = async () => {
			const guid = '948e88b6-5502-4cd4-a58e-fd2c39452377';
			const itemsFilter = 'items';
			const res = await getData(guid, itemsFilter);

			setPhases(res.data);
		};

		getPhases();
	}, []);

	//getting all sharepoint information to count and filter items in cards
	useEffect(() => {
		const getAllTopics = async () => {
			const guid = '036fa973-befc-4b54-80f5-67b0cd3db545';
			const itemsFilter = 'items?$top=5000';
			const res = await getData(guid, itemsFilter);

			setAllTopics(res.data);
		};

		const getAllModels = async () => {
			const guid = 'bf640899-46e4-4b2e-a054-3298a82ee14e';
			const itemsFilter = 'items?$top=5000';
			const res = await getData(guid, itemsFilter);

			setAllModels(res.data);
		};

		const getAllItems = async () => {
			const guid = '09ec9930-5bd6-4423-9219-8cbd19550a06';
			const itemsFilter = 'items?$top=5000';
			const res = await getData(guid, itemsFilter);

			setAllItems(res.data);
		};

		const getAllNews = async () => {
			const guid = '24075851-6373-4890-9665-807bdc713906';
			const itemsFilter = 'items?$top=5000';
			const res = await getData(guid, itemsFilter);

			setAllNews(res.data);
		};

		getAllTopics();
		getAllModels();
		getAllItems();
		getAllNews();
	}, []);

	//function to add number of 0 on left of numbers
	const padNumber = (number, pad) => {
		var N = Math.pow(10, pad);
		return number < N ? ('' + (N + number)).slice(1) : '' + number;
	};

	const getItemsList = (phaseID, targetPage, filterSubstr) => {
		document
			.querySelector('.posts-container')
			.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });

		let listID = '';

		switch (targetPage) {
			case 'topico':
				listID = '036fa973-befc-4b54-80f5-67b0cd3db545';
				setListGUID('036fa973-befc-4b54-80f5-67b0cd3db545');
				break;
			case 'modelo':
				listID = 'bf640899-46e4-4b2e-a054-3298a82ee14e';
				setListGUID('bf640899-46e4-4b2e-a054-3298a82ee14e');
				break;
			case 'oquedizalei':
				listID = '09ec9930-5bd6-4423-9219-8cbd19550a06';
				setListGUID('09ec9930-5bd6-4423-9219-8cbd19550a06');
				break;
			case 'noticia':
				listID = '24075851-6373-4890-9665-807bdc713906';
				setListGUID('24075851-6373-4890-9665-807bdc713906');
				break;
			default:
				break;
		}

		const filter =
			filterSubstr !== '' && filterSubstr !== undefined
				? `items?&$filter=(substringof('${filterSubstr}', Categoria) and Fase eq ${phaseID})&$skiptoken=Paged=TRUE&$top=10`
				: `items?&$filter=Fase eq ${phaseID}&$skiptoken=Paged=TRUE&$top=10`;

		setQuery(
			filterSubstr !== '' && filterSubstr !== undefined
				? `$filter=(substringof('${filterSubstr}', Categoria) and Fase eq ${phaseID})&$top=10`
				: `$filter=Fase eq ${phaseID}&$top=10`
		);

		const filterResults = async () => {
			const res = await getData(listID, filter);

			setItems(res.data);
			setTargetPage(targetPage);
			setHasMore(res.data.length > 0);
			setLoad(false);
		};

		filterResults();
	};

	const handleSendModel = () => {
		setType('download_modelo');
		toggle();
	};

	const phasesDescription = [
		{
			phase: 'Reivindicação',
			description:
				'Fase em que servidores externam para a Administração seus desejos e insatisfações relativas às condições de trabalho, oportunidade em que buscam negociar o atendimento de suas reivindicações',
		},
		{
			phase: 'Deflagração',
			description:
				'Fase que sucede o não atendimento das reivindicações dos servidores, em que a categoria insatisfeita delibera pela suspensão coletiva dos serviços',
		},
		{
			phase: 'Condução',
			description:
				'Fase em que os grevistas, representados pelo seu sindicato, utilizam de estratégias de manifestação e persuasão para incentivar a adesão dos servidores bem como para negociar com a Administração o atendimento das reivindicações',
		},
		{
			phase: 'Encerramento',
			description:
				'Fase em que a categoria delibera pelo retorno dos serviços, comumente ocasionada pelo sucesso das negociações com a Administração.',
		},
	];

	return (
		<div className='container'>
			<div className='timeline-cards-container'>
				{phases.map(phase => (
					<div className='card-timeline' key={phase.ID}>
						<div className='card-timeline-title'>{phase.Title}</div>
						<div className='card-timeline-body'>
						<div className='card-timeline-subtitle'>
							{phasesDescription.map(item =>
								item.phase === phase.Title ? item.description : ''
							)}
						</div>
							<table className='card-list'>
								<tbody>
									<tr>
										<td className='item-count'>
											{padNumber(
												allTopics.filter(item => item.FaseId === phase.ID).length,
												3
											)}
										</td>
										<td
											className='item-title'
											onClick={e => getItemsList(phase.ID, 'topico')}
										>
											Tópicos explicativos
										</td>
									</tr>
									<tr>
										<td className='item-count'>
											{padNumber(
												allModels.filter(item => item.FaseId === phase.ID).length,
												3
											)}
										</td>
										<td
											className='item-title'
											onClick={e => getItemsList(phase.ID, 'modelo')}
										>
											Modelos
										</td>
									</tr>
									<tr>
										<td className='item-count'>
											{padNumber(
												allItems.filter(item => {
													return (
														item.FaseId.results.indexOf(phase.ID) >= 0 &&
														item.Categoria === 'Jurisprudência'
													);
												}).length,
												3
											)}
										</td>
										<td
											className='item-title'
											onClick={e => {
												getItemsList(phase.ID, 'oquedizalei', 'Jurisprudência');
											}}
										>
											Regras constitucionais e legais
										</td>
									</tr>
									<tr>
										<td className='item-count'>
											{padNumber(
												allItems.filter(item => {
													return (
														item.FaseId.results.indexOf(phase.ID) >= 0 &&
														item.Categoria === 'Legislação'
													);
												}).length,
												3
											)}
										</td>
										<td
											className='item-title'
											onClick={e => getItemsList(phase.ID, 'oquedizalei', 'Legislação')}
										>
											Julgados
										</td>
									</tr>
									<tr>
										<td className='item-count'>
											{padNumber(
												allItems.filter(item => {
													return (
														item.FaseId.results.indexOf(phase.ID) >= 0 &&
														item.Categoria === 'Proposição legislativa'
													);
												}).length,
												3
											)}
										</td>
										<td
											className='item-title'
											onClick={e => {
												getItemsList(phase.ID, 'oquedizalei', 'Proposição legislativa');
											}}
										>
											Projetos de leis e propostas de emendas
										</td>
									</tr>
									<tr>
										<td className='item-count'>
											{padNumber(
												allNews.filter(item => item.FaseId === phase.ID).length,
												3
											)}
										</td>
										<td
											className='item-title'
											onClick={e => getItemsList(phase.ID, 'noticia')}
										>
											Noticias
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				))}
			</div>
			<div className='posts-container'>
				{/* {items.length === 0 ? (
					<div className='intro'>
						<p>
							<strong>Reivindicação:</strong> fase em que servidores externam para a
							Administração seus desejos e insatisfações relativas às condições de trabalho,
							oportunidade em que buscam negociar o atendimento de suas reivindicações
						</p>
						<p>
							<strong>Deflagração:</strong> fase que sucede o não atendimento das
							reivindicações dos servidores, em que a categoria insatisfeita delibera pela
							suspensão coletiva dos serviços
						</p>
						<p>
							<strong>Condução:</strong> fase em que os grevistas, representados pelo seu
							sindicato, utilizam de estratégias de manifestação e persuasão para incentivar
							a adesão dos servidores bem como para negociar com a Administração o
							atendimento das reivindicações
						</p>
						<p>
							<strong>Encerramento:</strong> fase em que a categoria delibera pelo retorno
							dos serviços, comumente ocasionada pelo sucesso das negociações com a
							Administração.
						</p>
					</div>
				) : (
					''
				)} */}
				{targetPage !== 'modelo' ? (
					<div className='posts-results'>
						{items.map((item, index) => {
							const lastIndex = index.toString().slice(-1);
							const last = parseInt(lastIndex);

							if (items.length === index + 1) {
								return (
									<PostsList
										key={item.ID}
										pageName={targetPage}
										post={item}
										animationOrder={last}
										reference={lastItem}
									/>
								);
							} else {
								return (
									<PostsList
										key={item.ID}
										pageName={targetPage}
										post={item}
										loading={load}
										animationOrder={last}
									/>
								);
							}
						})}
					</div>
				) : (
					<div className='results-models'>
						{items.map(item => {
							return (
								<div className='modelo-container' key={item.ID}>
									{item.Imagem !== null && item.Imagem !== undefined ? (
										<div
											className='modelo-imagem'
											style={{ backgroundImage: `url(${item.Imagem})` }}
										></div>
									) : (
										<div
											className='modelo-imagem'
											style={{ backgroundImage: `url(${PlaceholderImg})` }}
										></div>
									)}

									<div className='modelo-title'>{item.Title}</div>
									<button
										onClick={() => {
											handleSendModel();
											setModeloID(item.ID);
										}}
									>
										Baixar modelo <RiDownload2Line size={20} />
									</button>
								</div>
							);
						})}
					</div>
				)}

				{load === true ? (
					<div className='load-container'>
						{[...Array(5)].map((x, i) => (
							<SkeletonPostList key={i} />
						))}
					</div>
				) : (
					''
				)}
			</div>

			<Modal isShowing={isShowing} hide={toggle} type={type} itemID={modeloID} />
		</div>
	);
}
