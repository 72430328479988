import React from 'react';
import './styles.scss';

const SkeletonMosaico = () => {
	return (
		<div className='mosaico-loading'>
			<>
				{[...Array(13)].map((x, i) => (
					<div className='topico-loading loading' key={i}></div>
				))}
			</>
		</div>
	);
};

const SkeletonCard = () => {
	return (
		<div className='card-loading'>
			<div className='card-loading-container'>
				<figure className='card-loading-image loading'></figure>
				<div className='card-loading-title-container'>
					<div className='card-loading-title loading'></div>
					<div className='card-loading-title-half loading'></div>
				</div>
			</div>
		</div>
	);
};

const SkeletonPostList = () => {
	return (
		<div className='post-list post-list-item'>
			<div className='post-list-short-description'>
				<div className='post-list-title loading'></div>
				<div className='post-list-row loading'></div>
				<div className='post-list-row loading'></div>
				<div className='post-list-row-half loading'></div>
			</div>
		</div>
	);
};

const SkeletonPostContent = ({rows}) => {
	return (
		<div className='skeleton-post'>
			<div className='post-title loading'></div>
			{[...Array(rows)].map((x, i) => (
				<div className='post-row loading' key={i}></div>
			))}
			<div className='post-row-half loading'></div>
		</div>
	);
};

export { SkeletonMosaico, SkeletonCard, SkeletonPostContent, SkeletonPostList };
