import React, { useState, useEffect, useCallback, useRef } from 'react';
import './styles.scss';
import { IoIosSearch } from 'react-icons/io';
import { getData } from '../../services/api';
import PostsList from '../../components/PostList';
import { SkeletonPostList } from '../../components/Skeleton';

export default function OQueDizALei() {
	const guid = '09ec9930-5bd6-4423-9219-8cbd19550a06';

	const [load, setLoad] = useState(true);
	const [items, setItems] = useState([]);
	const [phases, setPhases] = useState([]);
	const [paginationFilter, setPaginationFilter] = useState([]);

	const [category, setCategory] = useState([]);
	const [actualPhase, setActualPhase] = useState([]);
	const [search, setSearch] = useState([]);

	const [hasMore, setHasMore] = useState(false);
	const observer = useRef();
	const lastItem = useCallback(
		node => {
			if (load) return;

			if (observer.current) observer.current.disconnect();

			observer.current = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting && hasMore) {
					setLoad(true);
					infinitePagination(paginationFilter);
				}
			});

			if (node) observer.current.observe(node);
		},

		[load, hasMore, paginationFilter]
	);

	useEffect(() => {
		const getFases = async () => {
			const listFases = '948e88b6-5502-4cd4-a58e-fd2c39452377';
			const itemsFases = 'items';
			const res = await getData(listFases, itemsFases);

			setPhases(res.data);
		};

		getFases();
	}, []);

	useEffect(() => {
		const buscarPosts = async () => {
			setLoad(true);

			const itemsFilter = 'items?&$orderby=ID desc&$skiptoken=Paged=TRUE&$top=10';
			const res = await getData(guid, itemsFilter);

			setHasMore(res.data.length > 0);
			setItems(res.data);
			setLoad(false);
		};

		const buscarPostsFiltrados = async () => {
			setLoad(true);
			const local = window.location.pathname.split('/').pop();
			let param = '';

			switch (local) {
				case 'legislacao':
					param = 'Legislação';
					break;
				case 'jurisprudencia':
					param = 'Jurisprudência';
					break;
				case 'proposicaolegislativa':
					param = 'Proposição legislativa';
					break;
				default:
					setLoad(true);
			}

			const itemsFilter = `items?&$orderby=ID desc&$filter=substringof('${param}', Categoria)&$top=10`;
			const res = await getData(guid, itemsFilter);

			setHasMore(res.data.length > 0);
			setItems(res.data);
			setLoad(false);
		};

		window.location.href.split('/').pop() === 'oquedizalei' ? buscarPosts() : buscarPostsFiltrados();
	}, []);

	const categoryFilter = category.length > 0 ? `substringof('${category}', Categoria)` : '';
	const phaseFilter = actualPhase > '0' ? `Fase eq ${actualPhase}` : '';
	const searchFilter = search.length > 0 ? `substringof('${search}', Title)` : '';

	document.querySelectorAll('.button').forEach(btn => {
		if (
			btn.innerText
				.toLowerCase()
				.normalize('NFD')
				.replace(/[\u0300-\u036f/' '/]/g, '') === window.location.href.split('/').pop() &&
			document.getElementById('search').value === ''
		) {
			btn.classList.add('active');
		}
	});

	function infinitePagination(filter) {
		const itemsContainer = document.querySelector('.resultados-container');
		const idOfLastItem =
			itemsContainer !== null && itemsContainer !== undefined && itemsContainer.hasChildNodes()
				? itemsContainer.lastElementChild.id
				: 0;

		async function pagination() {
			const itemsFilter =
				filter.length === 0
					? `items?&$orderby=ID desc&$skiptoken=Paged=TRUE%26p_ID=${idOfLastItem}&$top=10`
					: `items?&$orderby=ID desc&$skiptoken=Paged=TRUE%26p_ID=${idOfLastItem}&${filter}&$top=10`;

			const res = await getData(guid, itemsFilter);

			setItems(previousItems => {
				return [...new Set([...previousItems, ...res.data])];
			});

			setHasMore(res.data.length > 0);

			setLoad(false);
		}

		pagination(filter);
	}

	//filtro dos botoes
	function handleFilterBtn(e, param, pathname) {
		e.preventDefault();

		setCategory(param);

		function categoryPagination(phase, search) {
			if (search.length === 0 && phase.length === 0) return `$filter=substringof('${param}', Categoria)`;

			if (search.length === 0 && phase.length > 0)
				return `$filter=(substringof('${param}', Categoria) and ${phase})`;

			if (search.length > 0 && phase.length === 0)
				return `$filter=(substringof('${param}', Categoria) and ${search})`;

			if (search.length > 0 && phase.length > 0)
				return `$filter=(substringof('${param}', Categoria) and ${phase} and ${search})`;
		}

		const paginationFilterItem = categoryPagination(phaseFilter, searchFilter);
		const filteredItems = `items?&$orderby=ID desc&${paginationFilterItem}&$top=10`;

		setPaginationFilter(paginationFilterItem);

		window.history.pushState('', '', `#/oquedizalei/${pathname}`);

		async function categoryFilterResults() {
			setLoad(true);
			const res = await getData(guid, filteredItems);

			setItems(res.data);
			setHasMore(res.data.length > 0);
			setLoad(false);
		}

		categoryFilterResults();

		const elems = document.querySelectorAll('.active');
		[].forEach.call(elems, function (el) {
			el.classList.remove('active');
		});
		e.target.classList.add('active');
	}

	useEffect(() => {
		handlePhaseFilter();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [actualPhase]);

	// filtro dropdown --- react hooks setstate one step behind
	function handlePhaseFilter() {

		function phasePagination(category, search) {         

         const selectedPhase = actualPhase !== '0' ? `and ${phaseFilter}` : ''

         if (category.length === 0 && search.length === 0 && actualPhase !== '0') 
            return `&$orderby=ID desc&$filter=${phaseFilter}`;

         else if (category.length > 0 && search.length === 0) 
            return `&$orderby=ID desc&$filter=(${categoryFilter} ${selectedPhase})`;

         else if (category.length === 0 && search.length > 0) 
            return `&$orderby=ID desc&$filter=(${searchFilter} ${selectedPhase})`;

			else if (category.length > 0 && search.length > 0)
            return `&$orderby=ID desc&$filter=(${categoryFilter} and ${searchFilter} ${selectedPhase})`;

         else
            return ''
		}

		const phaseFilterItem = phasePagination(categoryFilter, searchFilter);
		const filteredItems = `items?&$orderby=ID desc${phaseFilterItem}&$top=10`;

		setPaginationFilter(phaseFilterItem);

		async function phaseFilterResults() {
			setLoad(true);
			const res = await getData(guid, filteredItems);

			setItems(res.data);
			setHasMore(res.data.length > 0);
			setLoad(false);
		}

		phaseFilterResults();
	}

	//filtro campo de busca
	function handleFilterSearch(e) {
		e.preventDefault();

		if (search.length === 0) return;

		const paginationFilter = `$orderby=ID desc$filter=substringof('${search}', Title)`;
		const filteredItems = `items?&$orderby=ID desc&$filter=substringof('${search}', Title)&$top=10`;

		setPaginationFilter(paginationFilter);

		async function filterResults() {
			setLoad(true);
			const res = await getData(guid, filteredItems);

			setItems(res.data);
			setHasMore(res.data.length > 0);
			setLoad(false);
		}

		filterResults();
	}

	return (
		<div className='container'>
			<div className='filtros-container'>
				<div className='item-filtro tipo'>
					<label>Informação</label>
					<div className='button-tipo-container'>
						<button className='button' onClick={e => handleFilterBtn(e, 'Legislação', 'legislacao')}>
							Legislação
						</button>
						<button className='button' onClick={e => handleFilterBtn(e, 'Jurisprudência', 'jurisprudencia')}>
							Jurisprudência
						</button>
						<button
							className='button'
							onClick={e => handleFilterBtn(e, 'Proposição legislativa', 'proposicaolegislativa')}
						>
							Proposição Legislativa
						</button>
					</div>
				</div>
				<div className='item-filtro fase'>
					<label>Etapas da greve</label>
					<select name='' id='drpFase' onChange={e => setActualPhase(e.target.value)}>
						<option value='0'>Todas as fases</option>
						{phases.map(phase => (
							<option value={phase.ID} key={phase.ID}>
								{phase.Title}
							</option>
						))}
					</select>
				</div>
				<div className='item-filtro busca'>
					<label htmlFor='search'>Pesquisar</label>
					<div className='search-container'>
						<input
							type='text'
							id='search'
							onChange={e => setSearch(e.target.value)}
							onKeyPress={e => (e.key === 'Enter' ? handleFilterSearch(e) : '')}
						/>
						<button onClick={e => handleFilterSearch(e)}>
							<IoIosSearch size={20} />
						</button>
					</div>
				</div>
			</div>
			<div className='resultados-container'>
				{items.map((item, index) => {
					const lastIndex = index.toString().slice(-1);
					const last = parseInt(lastIndex);

					if (items.length === index + 1) {
						return (
							<PostsList
								key={item.ID}
								pageName={'oquedizalei'}
								post={item}
								animationOrder={last}
								reference={lastItem}
							/>
						);
					} else {
						return (
							<PostsList
								key={item.ID}
								pageName={'oquedizalei'}
								post={item}
								loading={load}
								animationOrder={last}
							/>
						);
					}
				})}
			</div>
			{load === true ? (
				<div className='load-container'>
					{[...Array(5)].map((x, i) => (
						<SkeletonPostList key={i} />
					))}
				</div>
			) : (
				''
			)}
		</div>
	);
}
