import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';
import { sendEmail, sendModel, sendMaterial, addPersonToPipe } from '../../services/api';

const Modal = ({ isShowing, hide, type, itemID, array }) => {
	const node = useRef();
	const [sendLoad, setSendLoad] = useState(false);
	const [name, setName] = useState([]);
	const [email, setEmail] = useState([]);
	const [phone, setPhone] = useState([]);
	const [dirigente, setDirigente] = useState(false);
	const [entity, setEntity] = useState([]);
	const [message, setMessage] = useState([]);
	const [saibaMais, setSaibaMais] = useState(false);

	useEffect(() => {
		if (isShowing) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isShowing]);

	const acceptTerms = () => {
		return(
			<div className="form-group">
				<input type="checkbox" required id="acceptcheck"
					style={{height: 'initial', width: 'initial', padding: 0, marginRight: '5px'}} />
				<label for="acceptcheck">Eu concordo em receber comunicações.</label>
				<p style={{fontSize:'0.8em'}}>Ao informar meus dados, eu concordo que eles sejam tratados, de acordo com as regras da LGPD. Para mais informações, acesse na nossa <a href="https://conteudo.servidor.adv.br/politica-de-protecao-de-dados" target='_blank'>Política de Privacidade.</a></p>
			</div>
		);
	};

	const handleContact = async () => {
		if (name.length <= 0 && email.length <= 0) {
			alert('há campos requiridos em branco, por favor preencha-os e tente novamente');
			return;
		} else {
			await addPersonToPipe(name, phone, email);

			const formData = dirigente
				? `<p>Nome: ${name}</p>
			     <p>Email: ${email}</p>
			     <p>Telefone: ${phone}</p>
			     <p>Dirigente: Sim</p>
			     <p>Entidade: ${entity}</p>
           <p>Mensagem: ${message}</p>`
				: `<p>Nome: ${name}</p>
			     <p>Email: ${email}</p>
			     <p>Telefone: ${phone}</p>
			     <p>Dirigente: Não</p>
			     <p>Mensagem: ${message}</p>`;

			const res = await sendEmail(formData);

			if (res.statusRes === 202) setSendLoad(true);
		}
	};
	const handleModel = async () => {
		await addPersonToPipe(name, phone, email);

		const res = await sendModel(itemID, email);

		if (res.statusRes === 202) setSendLoad(true);
		
	};

	const handleMaterial = async () => {
		await addPersonToPipe(name, email);

		const res = await sendMaterial(itemID, email);

		if (res.statusRes === 202) setSendLoad(true);
	};

	const handleEmergency = async () => {
		if (name.length <= 0 && phone.length <= 0 && message.length <= 0) {
			alert('há campos requiridos em branco, por favor preencha-os e tente novamente');
			return;
		} else {
			const messageBody = dirigente
				? encodeURIComponent(
						`*[EMERGÊNCIA]*\n*Nome:* ${name}\n*Tel:* ${phone}\n*Dirigente da entidade:* ${entity}\n*Assunto:* ${message}`
				  )
				: encodeURIComponent(`*[EMERGÊNCIA InfoGreve]*\n*Nome:* ${name}\n*Tel:* ${phone}\n*Assunto:* ${message}`);

			const link = `https://api.whatsapp.com/send?phone=5561999595909&text=${messageBody}`;

			window.open(link, '_blank');

			setSendLoad(true);
		}
	};

	const handleQa = async () => {
		if (name.length <= 0 && email.length <= 0) {
			alert('há campos requiridos em branco, por favor preencha-os e tente novamente');
			return;
		} else {
			await addPersonToPipe(name, email);
		}
	};

	const handleForm = e => {
		e.preventDefault();

		if (type === 'fale_conosco') handleContact();
		if (type === 'download_modelo') handleModel();
		if (type === 'emergencia') handleEmergency();
		if (type === 'modal_qa') handleQa();
		if (type === 'direct_download') handleMaterial();
	};

	const handleClickOutside = e => {
		if (node.current.contains(e.target)) {
			// inside click
			return;
		}
		// outside click
		closeModal();
	};

	const phoneMask = e => {
		e.target.addEventListener('input', function (e) {
			var x = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
			e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
		});

		setPhone(e.target.value);
	};

	const closeModal = () => {
		hide();
		setSendLoad(false);
		setSaibaMais(false);
	};

	function htmlToText(html) {
		const doc = new DOMParser().parseFromString(html, 'text/html');
		if (doc.body.querySelectorAll('p').length > 0) return doc.body.querySelectorAll('p');
		else return doc.body.querySelectorAll('div');
	}

	const handleSaibaMais = e => {
		e.preventDefault();

		setSaibaMais(true);
	};

	return isShowing
		? ReactDOM.createPortal(
				<React.Fragment>
					<div className='modal-overlay' />
					<div className='modal-wrapper' aria-modal aria-hidden tabIndex={-1} role='dialog'>
						<div className={`modal ${type}`} ref={node}>
							<button
								type='button'
								className='modal-close-button'
								data-dismiss='modal'
								aria-label='Close'
								onClick={closeModal}
							>
								<span aria-hidden='true'>&times;</span>
							</button>

							{!sendLoad ? (
								<form onSubmit={handleForm}>
									{type === 'fale_conosco' && (
										<>
											<div className='form-group'>
												<label htmlFor='name'>
													Nome <span className='required-field'>*</span>
												</label>
												<input type='text' id='name' onChange={e => setName(e.target.value)} required />
											</div>

											<div className='form-group'>
												<label htmlFor='email'>
													Email <span className='required-field'>*</span>
												</label>
												<input type='text' id='email' onChange={e => setEmail(e.target.value)} required />
											</div>

											<div className='form-group'>
												<label htmlFor='phone'>Telefone/WhatsApp</label>
												<input id='phone' onChange={e => phoneMask(e)} />
											</div>

											<div className='form-group'>
												<label htmlFor='dirigente'>Dirigente?</label>
												<select
													type='text'
													id='dirigente'
													onChange={e => setDirigente(e.target.value === '1' ? true : false)}
												>
													<option value='0'>Não</option>
													<option value='1'>Sim</option>
												</select>
											</div>

											{dirigente && (
												<div className='form-group'>
													<label htmlFor='entidade'>Entidade</label>
													<input type='text' id='entidade' onChange={e => setEntity(e.target.value)} />
												</div>
											)}

											<div className='form-group'>
												<label htmlFor='descricao'>Mensagem</label>
												<textarea
													name='descricao'
													id='descricao'
													rows='6'
													onChange={e => setMessage(e.target.value)}
												></textarea>
											</div>
											{acceptTerms()}

                      <button className='button button--featured' type='submit'>
												Enviar por email
											</button>
										</>
									)}

									{type === 'download_modelo' && (
										<>
											<div className='form-group'>
												<label htmlFor='name'>
													Nome <span className='required-field'>*</span>
												</label>
												<input type='text' id='name' onChange={e => setName(e.target.value)} required />
											</div>

											<div className='form-group'>
												<label htmlFor='email'>
													Email <span className='required-field'>*</span>
												</label>
												<input type='text' id='email' onChange={e => setEmail(e.target.value)} required />
											</div>

											<div className='form-group'>
												<label htmlFor='phone'>Telefone/WhatsApp</label>
												<input id='phone' onChange={e => phoneMask(e)} />
											</div>

											<div className='form-group'>
												<label htmlFor='dirigente'>Dirigente?</label>
												<select
													type='text'
													id='dirigente'
													onChange={e => setDirigente(e.target.value === '1' ? true : false)}
												>
													<option value='0'>Não</option>
													<option value='1'>Sim</option>
												</select>
											</div>

											{dirigente && (
												<div className='form-group'>
													<label htmlFor='entidade'>Entidade</label>
													<input type='text' id='entidade' onChange={e => setEntity(e.target.value)} />
												</div>
											)}
											{acceptTerms()}

                      <button className='button button--featured' type='submit'>
												Enviar modelo por email
											</button>

										</>
									)}

									{type === 'emergencia' && (
										<>
											<div className='form-group'>
												<label htmlFor='name'>
													Nome <span className='required-field'>*</span>
												</label>
												<input type='text' id='name' onChange={e => setName(e.target.value)} required />
											</div>

											<div className='form-group'>
												<label htmlFor='phone'>
													Telefone/WhatsApp <span className='required-field'>*</span>
												</label>
												<input id='phone' onChange={e => phoneMask(e)} required />
											</div>

											<div className='form-group'>
												<label htmlFor='dirigente'>Dirigente?</label>
												<select
													type='text'
													id='dirigente'
													onChange={e => setDirigente(e.target.value === '1' ? true : false)}
												>
													<option value='0'>Não</option>
													<option value='1'>Sim</option>
												</select>
											</div>

											{dirigente ? (
												<div className='form-group'>
													<label htmlFor='entidade'>Entidade</label>
													<input type='text' id='entidade' onChange={e => setEntity(e.target.value)} />
												</div>
											) : (
												''
											)}

											<div className='form-group'>
												<label htmlFor='descricao'>Assunto</label>
												<textarea
													name='descricao'
													id='descricao'
													rows='3'
													onChange={e => setMessage(e.target.value)}
												></textarea>
											</div>
											{acceptTerms()}

                      <button className='button button--featured' type='submit'>
												Enviar Mensagem
											</button>

										</>
									)}

									{type === 'direct_download' && (
										<>
											<div className='form-group'>
												<label htmlFor='name'>
													Nome <span className='required-field'>*</span>
												</label>
												<input type='text' id='name' onChange={e => setName(e.target.value)} required />
											</div>

											<div className='form-group'>
												<label htmlFor='email'>
													Email <span className='required-field'>*</span>
												</label>
												<input type='text' id='email' onChange={e => setEmail(e.target.value)} required />
											</div>
											{acceptTerms()}
                      <button className='button button--featured' type='submit'>
												Enviar por email
											</button>
										</>
									)}

									{type === 'modal_qa' && (
										<div className='modal_qa'>
											<h2 className='title'>{array[0].Title}</h2>

											<div className='text'>
												{Array.from(htmlToText(array[0].Resposta)).map((el, index) => (
													<p className='post-p' key={index}>
														{el.textContent}
													</p>
												))}
											</div>

											{saibaMais ? (
												<div className='saiba-mais-container'>
													<div className='form-wrapper'>
														<div className='form-group'>
															<label htmlFor='name'>
																Nome <span className='required-field'>*</span>
															</label>
															<input type='text' id='name' onChange={e => setName(e.target.value)} required />
														</div>

														<div className='form-group'>
															<label htmlFor='email'>
																Email <span className='required-field'>*</span>
															</label>
															<input type='text' id='email' onChange={e => setEmail(e.target.value)} required />
														</div>
													</div>
													<div className='button-wrapper'>
														<button className='button button--featured' type='submit'>
															Quero saber mais
														</button>
													</div>
												</div>
											) : (
												<button className='button button--featured' onClick={e => handleSaibaMais(e)}>
													Saiba mais
												</button>
											)}
										</div>
									)}
								</form>
							) : (
								<div className='send-message'>
									{type === 'download_modelo' || type === 'direct_download' ? (
										<p>
											O arquivo solicitado foi enviado para seu email, caso não o receba em alguns minutos verifique sua
											caixa de Spam
										</p>
									) : (
										<p>Sua solicitação foi enviada com sucesso!</p>
									)}
								</div>
							)}
						</div>
					</div>
				</React.Fragment>,
				document.body
		  )
		: null;
};

export default Modal;
