import React, { useState, useEffect } from 'react';
import './styles.scss';
import { getData } from '../../services/api';
import PostContent from '../../components/PostContent';
import { Link, useHistory } from 'react-router-dom';
import Modal from '../../components/Modal';
import useModal from '../../hooks/useModal';

export default function Post() {
	const [load, setLoad] = useState(true);

	const { isShowing, toggle } = useModal();
	const [type, setType] = useState([]);

	const [listID, setListID] = useState([]);
	const [item, setItem] = useState([]);
	const [resposta, setResposta] = useState([]);
	const [relatedItems, setRelatedItems] = useState([]);
	const [relatedNews, setRelatedNews] = useState([]);
	const [allRelatedNews, setAllRelatedNews] = useState([]);
	const [pageName, setPageName] = useState([]);
	const history = useHistory();

	async function getPost() {
		const local = window.location.href;
		const filter = `items?$filter=ID eq ${window.location.href.split('=').pop()}`;

		switch (true) {
			case local.includes('topico'):
				setListID('036fa973-befc-4b54-80f5-67b0cd3db545');
				break;
			case local.includes('oquedizalei'):
				setListID('09ec9930-5bd6-4423-9219-8cbd19550a06');
				break;
			case local.includes('noticia'):
				setListID('24075851-6373-4890-9665-807bdc713906');
				break;
			case local.includes('pergunta'):
				setListID('7011c3b6-f547-406d-8001-c89437d92f24');
				break;
			default:
				break;
		}

		console.log(listID);

		if (listID.length === 0) return;

		const res = await getData(listID, filter);

		if (res.data.length > 0) setItem(res.data);
	}

	async function processInfo() {
		if (item.length === 0) return;

		if (window.location.href.includes('oquedizalei')) {
			const guidRelated = '036fa973-befc-4b54-80f5-67b0cd3db545';
			const topicsIds = item.map(x =>
				x.TopicosRelacionadosId !== undefined ? x.TopicosRelacionadosId.results : ''
			);
			const items = [];

			async function getRelatedTopics() {
				for (const topico of topicsIds[0]) {
					const filterRelated = `items?$filter=ID eq ${topico}`;
					const res = await getData(guidRelated, filterRelated);
					items.push({ ID: res.data[0].ID, Title: res.data[0].Title });
				}

				setRelatedItems(items);
				setLoad(false);
			}

			getRelatedTopics();
			return;
		}

		if (window.location.href.includes('topico')) {
			const guidRelated = '7011c3b6-f547-406d-8001-c89437d92f24';
			const guidNews = '24075851-6373-4890-9665-807bdc713906';

			async function getRelatedTopics() {
				const filterRelated = `items`;
				const filterTopicos = `items?&$orderby=ID desc&$filter=Topicos eq ${parseInt(
					window.location.href.split('?item=').pop()
				)}&$top=5`;
				const resFilter = await getData(guidRelated, filterRelated);
				const resNews = await getData(guidNews, filterTopicos);

				setRelatedItems(
					resFilter.data.filter(
						x => x.TopicoId === parseInt(window.location.href.split('?item=').pop())
					)
				);

				setRelatedNews(resNews.data);
				setLoad(false);
			}

			async function getAllRelatedNews() {
				const filterNews = `items?&$orderby=ID desc&$filter=Topicos eq ${parseInt(
					window.location.href.split('?item=').pop()
				)}&$top=5000`;
				const resNews = await getData(guidNews, filterNews);

				setAllRelatedNews(resNews.data.length);
				setLoad(false);
			}

			getAllRelatedNews();
			getRelatedTopics();
			return;
		} else {
			setLoad(false);
		}
	}

	useEffect(() => {
		getPost();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listID, pageName]);

	useEffect(() => {
		processInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item]);

	const modalQa = async itemId => {
		const guid = '7011c3b6-f547-406d-8001-c89437d92f24';
		const res = await getData(guid, `items?$filter=ID eq ${itemId}`);

		setResposta(res.data);
		setType('modal_qa');
		toggle();
	};

	history.listen(location => {
		setPageName(location.pathname);
	});

	return (
		<>
			{relatedItems.length !== 0 && !window.location.href.includes('noticia') ? (
				<div className='post post-related'>
					<div className='post-container'>
						<PostContent post={item} loading={load} />
					</div>

					<div className='related'>
						{window.location.href.includes('oquedizalei') ? (
							<div className='related-container'>
								<h3 className='related-title'>Topicos relacionados</h3>
								<ul className='related-links'>
									{relatedItems.map(topico => (
										<li key={topico.ID}>
											<Link to={`/post/topico/?item=${topico.ID}`} key={topico.ID}>
												<div className='title'>{topico.Title}</div>
											</Link>
										</li>
									))}
								</ul>
							</div>
						) : (
							''
						)}

						{window.location.href.includes('topico') ? (
							<div className='related-container'>
								<div className='related-box'>
									<h3 className='related-title'>Perguntas e respostas</h3>
									<ul className='related-links'>
										{relatedItems.map(qa => (
											<li
												className='related-qa'
												key={qa.ID}
												onClick={() => modalQa(qa.ID)}
											>
												<div className='title'>{qa.Title}</div>
											</li>
										))}
									</ul>
								</div>

								<div className='related-news'>
									<h3 className='related-title'>Noticias relacionadas</h3>
									<ul>
										{relatedNews.map(topico => (
											<li className='related-news-item' key={topico.ID}>
												<Link to={`/post/noticia/?item=${topico.ID}`}>
													<div className='title'>{topico.Title}</div>
												</Link>
											</li>
										))}
									</ul>

									<Link to={`/noticias/${window.location.href.split('=')[1]}`}>
										Ver todas as noticias relacionadas ({allRelatedNews})
									</Link>
								</div>
							</div>
						) : (
							''
						)}
					</div>
				</div>
			) : (
				<div className='post'>
					<div className='post-container'>
						<PostContent post={item} loading={load} />
					</div>
				</div>
			)}

			<Modal isShowing={isShowing} hide={toggle} type={type} array={resposta} />
		</>
	);
}
