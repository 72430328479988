import React, { useState, useEffect } from 'react';
import './styles.scss';
import { addPersonToPipe, getData, getDataGeral } from '../../services/api';
import Modal from '../../components/Modal';
import useModal from '../../hooks/useModal';
import { TopicosEntendaGreve } from '../../components/Topicos';

export default function EntendaAGreve() {
	const [name, setName] = useState([]);
	const [email, setEmail] = useState([]);
	const [noticias, setNoticias] = useState([]);
	const [topicos, setTopicos] = useState([]);
	const [loadTopicos, setLoadTopicos] = useState(true);
	const [autores, setAutores] = useState([]);
	const [resposta, setResposta] = useState([]);
	const [type, setType] = useState([]);
	const [perguntasRespostas, setPerguntasRespostas] = useState([]);
	const { isShowing, toggle } = useModal();

	useEffect(() => {
		const buscarTopicos = async () => {
			const guid = '036fa973-befc-4b54-80f5-67b0cd3db545';
			const res = await getData(guid, 'items');

			setTopicos(res.data);
			setLoadTopicos(false);
		};

		const buscarNoticias = async () => {
			const guid = '24075851-6373-4890-9665-807bdc713906';
			const res = await getData(guid, 'items?&$orderby=ID desc&$top=5000');

			setNoticias(res.data);
		};

		const buscarAutores = async () => {
			const filter = 'items';
			const res = await getDataGeral(filter);

			setAutores(res.data);
		};

		const buscarPerguntasRespostas = async () => {
			const guid = '7011c3b6-f547-406d-8001-c89437d92f24';
			const res = await getData(guid, 'items?&$orderby=ID desc&$top=5000');

			setPerguntasRespostas(res.data);
		};

		buscarTopicos();
		buscarNoticias();
		buscarAutores();
		buscarPerguntasRespostas();
	}, []);

	useEffect(() => {
		document.querySelectorAll('.related-news-list').forEach(ul => {
			filtrarNoticias(noticias, ul.id.split('-')[1]);
		});
	}, [noticias]);

	const filtrarNoticias = (array, ID) => {
		for (let i = 0; i < array.length; i++) {
			if (array[i].TopicosId.results.includes(parseInt(ID))) {
				const listItem = `<li className='related-news-item'>
                                    <a href=${`/post/noticia/?item=${array[i].ID}`}>
                                        <div className='title'>${array[i].Title}</div>
                                    </a>
                                </li>`;

				if (
					!document.querySelector(`#topico-${ID}`).hasChildNodes('li') ||
					document.querySelectorAll(`#topico-${ID} li`).length <= 4
				) {
					document.querySelector(`#topico-${ID}`).insertAdjacentHTML('afterbegin', listItem);
				}
			}
		}

		const totalFilteredItems = array.filter(x => x.TopicosId.results.includes(parseInt(ID)))
			.length;

		const listItemTotal = `<li className='related-news-item'>
                                    <a href=${`/noticias/${ID}`}>
                                        <div className='title'>
                                            Notícias relacionadas ( ${totalFilteredItems} )
                                        </div>
                                    </a>
                                </li>`;

		if (
			document.querySelector(`#topico-${ID}`).hasChildNodes('li') &&
			document.querySelectorAll(`#topico-${ID} li`).length > 4
		) {
			document.querySelector(`#topico-${ID}`).insertAdjacentHTML('beforeend', listItemTotal);
		}
	};

	function htmlToText(html) {
		const doc = new DOMParser().parseFromString(html, 'text/html');
		if (doc.body.querySelectorAll('p').length > 0) return doc.body.querySelectorAll('p');
		else return doc.body.querySelectorAll('div');
	}

	const modalQa = async itemId => {
		const guid = '7011c3b6-f547-406d-8001-c89437d92f24';
		const res = await getData(guid, `items?$filter=ID eq ${itemId}`);

		setResposta(res.data);
		setType('modal_qa');
		toggle();
	};

	const saibaMaisEntendaAGreve = async e => {
		e.preventDefault();

		await addPersonToPipe(name, email).then(() => {
			setName([]);
			setEmail([]);
			document.querySelector('#name').value = '';
			document.querySelector('#email').value = '';
			alert('Solicitação enviada com sucesso!');
		});
	};

	return (
		<div className='container'>
			<div className='section-container section-topicos'>
				<TopicosEntendaGreve arrayTopicos={topicos} loading={loadTopicos} />
			</div>

			<hr />
			<div className='alltopics-wrapper'>
				{topicos.map((topico, index) => (
					<div className={`topico-wrapper topico-${topico.ID}`} key={index}>
						<div className='topico-container'>
							<div className='item-title'>
								<h1>{topico.Title}</h1>
								{topico.AutoresId !== null && topico.AutoresId !== undefined ? (
									<div className='autor'>
										{autores.length > 0 ? (
											<span>
												Por:{' '}
												{autores.filter(x => x.ID === topico.AutoresId)[0].FullName}
											</span>
										) : (
											''
										)}
									</div>
								) : (
									''
								)}
							</div>
							{Array.from(htmlToText(topico.Texto)).map((el, index) => (
								<p className='post-p' key={index}>
									{el.textContent}
								</p>
							))}
						</div>

						<div className='related-container'>
							<div className='related-box'>
								<h3 className='related-title'>Perguntas e respostas</h3>
								<ul className='related-qa-container'>
									{perguntasRespostas.map(qa =>
										qa.TopicoId === topico.ID ? (
											<li
												className='related-qa'
												key={qa.ID}
												onClick={() => modalQa(qa.ID)}
											>
												<div className='title'>{qa.Title}</div>
											</li>
										) : (
											''
										)
									)}
								</ul>
							</div>

							<div className='related-news'>
								<h3 className='related-title'>Noticias relacionadas</h3>
								<ul className='related-news-list' id={`topico-${topico.ID}`}></ul>
							</div>
						</div>
					</div>
				))}
			</div>

			<form className='saiba-mais-container entendagreve' onSubmit={saibaMaisEntendaAGreve}>
				<div className='form-wrapper'>
					<div className='form-group'>
						<label htmlFor='name'>
							Nome <span className='required-field'>*</span>
						</label>
						<input type='text' id='name' onChange={e => setName(e.target.value)} required />
					</div>

					<div className='form-group'>
						<label htmlFor='email'>
							Email <span className='required-field'>*</span>
						</label>
						<input type='text' id='email' onChange={e => setEmail(e.target.value)} required />
					</div>
				</div>
				<div className='button-wrapper'>
					<button className='button button--featured' type='submit'>
						Saiba mais
					</button>
				</div>
			</form>

			<Modal isShowing={isShowing} hide={toggle} type={type} array={resposta} />
		</div>
	);
}
