import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import EntendaAGreve from './pages/EntendaAGreve';
import Post from './pages/Post';
import OQueDizALei from './pages/OQueDizALei';
import EtapasDaGreve from './pages/EtapasDaGreve';
import Noticias from './pages/Noticias';
import Footer from './components/Footer';
import Logo from '../src/images/logo.png';

export default function Routes() {
	const logo = Logo;
	const logoAlt = 'InfoGreve - Direito dos servidores em greve';
	const links = [
		{ linkTitle: 'Entenda a greve', linkTo: 'entendaagreve' },
		{ linkTitle: 'O que diz a lei', linkTo: 'oquedizalei' },
		{ linkTitle: 'Etapas da greve', linkTo: 'etapasdagreve' },
		{ linkTitle: 'Noticias', linkTo: 'noticias' },
	];

	return (
		
			<HashRouter basename='/'>
				<Navbar logo={logo} logoAlt={logoAlt} links={links} />
				<div className='main'>
					<Switch>
						<Route path='/' exact component={Home} />
						<Route path='/entendaagreve' component={EntendaAGreve} />
						<Route path='/post' component={Post} />
						<Route path='/oquedizalei' component={OQueDizALei} />
						<Route path='/etapasdagreve' component={EtapasDaGreve} />
						<Route path='/noticias' component={Noticias} />
					</Switch>
				</div>
				<Footer logo={logo} logoAlt={logoAlt} />
			</HashRouter>
		
	);
}
