import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import { SkeletonMosaico } from '../Skeleton';

function Topicos({ arrayTopicos, loading }) {
	if (loading) return <SkeletonMosaico />;

	return (
		<div className='topicos-container'>
			{arrayTopicos.map((topico, index) => (
				<Link
					to={`/post/topico/?item=${topico.ID}`}
					className={`topico topico-${index}`}
					key={topico.ID}
					style={{
						order: index,
						'--animation-order': index,
					}}
				>
					<div className='overlay'></div>
					<span>{topico.Title}</span>
				</Link>
			))}
		</div>
	);
}

function TopicosEntendaGreve({ arrayTopicos, loading }) {
	if (loading) return <SkeletonMosaico />;

	const scrollToElement = e => {
		const element = document.querySelector(
			`.topico-wrapper.topico-${e.target.parentElement.className.split(' ')[2].split('-')[2]}`
		);
		const headerOffset = 100;
		const elementPosition = element.getBoundingClientRect().top;
		const offsetPosition = elementPosition - headerOffset;

		window.scrollTo({
			top: offsetPosition,
			behavior: 'smooth',
		});
	};

	return (
		<div className='topicos-container'>
			{arrayTopicos.map((topico, index) => (
				<div
					className={`topico topico-${index} topico-id-${topico.ID}`}
					key={topico.ID}
					style={{
						order: index,
						'--animation-order': index,
					}}
					onClick={e => scrollToElement(e)}
				>
					<div className='overlay'></div>
					<span>{topico.Title}</span>
				</div>
			))}
		</div>
	);
}

export { Topicos, TopicosEntendaGreve };
