import React, { useState, useEffect } from 'react';
import { getData } from '../../services/api';
import './styles.scss';
import { SkeletonCard } from '../Skeleton';
import Slider from 'react-slick';
import CardVertical from '../Cards';

export const NewsSlider = () => {
	const [noticias, setNoticias] = useState([]);
	const [loadCardsNoticia, setLoadCardsNoticia] = useState(true);

	const buscarNoticias = async () => {
		const guid = '24075851-6373-4890-9665-807bdc713906';
		const itemsFilter = `items?&$orderby=DataPublicacao desc&$skiptoken=Paged=TRUE&$top=10`;
		const res = await getData(guid, itemsFilter);

		const noticiasCard = [];

		for (let i = 0; i < 5; i++) {
			noticiasCard.push(res.data[i]);
		}

		setTimeout(() => {
			setLoadCardsNoticia(false);
		}, 100);

		setNoticias(noticiasCard);
	};

	useEffect(() => {
		buscarNoticias();
	}, []);

	const sliderSettings = {
		className: 'center',
		centerMode: true,
		infinite: true,
		centerPadding: '25%',
		slidesToShow: 1,
		speed: 500,

		responsive: [
			{
				breakpoint: 1024,
				settings: {
					centerMode: true,
					infinite: true,
					centerPadding: '16%',
					slidesToShow: 1,
					speed: 500,
				},
			},
			{
				breakpoint: 600,
				settings: {
					centerMode: true,
					infinite: true,
					centerPadding: '8%',
					slidesToShow: 1,
					speed: 500,
				},
			},
		],
	};

	return (
		<div style={{ width: '100%' }}>
			{loadCardsNoticia ? (
				<div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px', maxWidth: '1280px', margin: '0 auto 40px' }}>
					{[...Array(3)].map((x, i) => (
							<SkeletonCard key={i} />
					))}
				</div>
			) : (
				<Slider {...sliderSettings}>
					{noticias.map((item, index) => (
						<div className='slider-item' key={item.ID}>
							<CardVertical item={item} index={index} linkTo={`/post/noticia/?item=${item.ID}`} />
						</div>
					))}
				</Slider>
			)}
		</div>
	);
};
