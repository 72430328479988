import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';

export default function Footer({ logo, logoAlt }) {
	return (
		<footer className='footer'>
			<div className='footer-container'>
				<Link to='/'>
					<img className='logo-footer' src={logo} alt={logoAlt} />
				</Link>				
			</div>
		</footer>
	);
}
