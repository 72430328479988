import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import { Topicos } from '../../components/Topicos';
import QueDizLei from '../../components/QueDizLei';
import { getData, sendEmail, addPersonToPipe } from '../../services/api';
import Facebook from '../../images/blockimg-fb.png';
import YouTube from '../../images/blockimg-yt.png';
import ServidorLegal from '../../images/blockimg-servidor-legal.png';
import Ebook from '../../images/blockimg-ebook.png';
import Panfleto from '../../images/blockimg-panfleto.png';
import Livro from '../../images/blockimg-livro.png';
import Modal from '../../components/Modal';
import useModal from '../../hooks/useModal';
import { RiDownload2Line, RiBookReadLine } from 'react-icons/ri';
import { NewsSlider } from '../../components/NewsSlider';

export default function Home() {
	const { isShowing, toggle } = useModal();
	const [type, setType] = useState([]);
	const [modeloID, setModeloID] = useState([]);

	const [loadTopicos, setLoadTopicos] = useState(true);

	const [topicos, setTopicos] = useState([]);
	const [modelos, setModelos] = useState([]);

	const [nome, setNome] = useState([]);
	const [email, setEmail] = useState([]);
	const [telefone, setTelefone] = useState([]);
	const [complemento, setComplemento] = useState([]);
	const [mensagem, setMensagem] = useState([]);

	const labelComplemento = document.querySelector('#labelComplemento');

	const options = [
		{ value: '1', title: 'Sou servidor público na ativa' },
		{ value: '2', title: 'Sou servidor público aposentado' },
		{ value: '3', title: 'Represento uma entidade de classe' },
		{ value: '4', title: 'Sou candidato em concurso público' },
	];

	const iniciativas = [
		{
			item: 'ebook',
			img: Ebook,
			href: 'https://conteudo.servidor.adv.br/ebook-as-etapas-da-greve-dos-servidores-publicos'
		},
		{
			item: 'panfleto',
			img: Panfleto,
			refID: 4,
		},
		{
			item: 'livro',
			img: Livro,
			href: 'https://www.amazon.com.br/Justi%C3%A7a-Greve-2019-Robson-Barbosa/dp/8551912208',
		},
		{
			item: 'facebook',
			img: Facebook,
			href: 'https://goo.gl/i54K2c',
		},
		{
			item: 'youtube',
			img: YouTube,
			href: 'https://goo.gl/P6VeKc',
		},
		{
			item: 'blog',
			img: ServidorLegal,
			href: 'https://www.blogservidorlegal.com.br/',
		},
	];

	useEffect(() => {
		const buscarTopicos = async () => {
			const guid = '036fa973-befc-4b54-80f5-67b0cd3db545';
			const res = await getData(guid, 'items');

			if (res.data.length === 0) return [];

			setTopicos(res.data);
			setLoadTopicos(false);
		};

		const buscarModelos = async () => {
			const guid = 'bf640899-46e4-4b2e-a054-3298a82ee14e';
			const itemsFilter = `items?&$orderby=Modified desc`;
			const res = await getData(guid, itemsFilter);

			if (res.data.length === 0) return [];

			setModelos(res.data);
		};

		buscarTopicos();
		buscarModelos();
	}, []);

	function MudarLabelFormContato(e) {
		switch (e.target.value) {
			case '1':
				labelComplemento.innerHTML = 'Nome do órgão em que trabalha';
				break;
			case '2':
				labelComplemento.innerHTML = 'Nome do órgão em que se aposentou';
				break;
			case '3':
				labelComplemento.innerHTML = 'Nome da entidade';
				break;
			case '4':
				labelComplemento.innerHTML = 'Qual concurso?';
				break;
			default:
				labelComplemento.innerHTML = 'Nome do órgão em que trabalha';
				break;
		}
	}

	const handleContact = async () => {
		if (nome.length <= 0 && email.length <= 0) {
			alert('há campos requiridos em branco, por favor preencha-os e tente novamente');
			return;
		}
		
		await addPersonToPipe(nome, telefone, email);
		const formData = `<p>Nome: ${nome}</p>
				<p>Email: ${email}</p>
				<p>Telefone: ${telefone}</p>
				<p>Perfil: ${labelComplemento.innerHTML}</p>
				<p>Complemento do Perfil: ${complemento}</p>
		<p>Mensagem: ${mensagem}</p>`;

		const res = await sendEmail(formData);
		if (res.statusRes === 202)
			alert('Formulário enviado com sucesso');
	};

	function handleSendModel() {
		setType('download_modelo');
		toggle();
	}

	const downloadFile = () => {
		setType('direct_download');
		toggle();
	};

	return (
		<>
			<div className='section-container section-topicos'>
				<div className='container'>
					<Topicos arrayTopicos={topicos} loading={loadTopicos} />
				</div>
			</div>

			<div className='section-container section-cards'>
				<div className='section-cards-container cards-vertical'>
					<NewsSlider />
				</div>
        <div className='container' style={{display: 'flex'}}>
				  <Link to={'/noticias'} className='button button--featured'>
				  	Ver todas as noticias
				  </Link>
        </div>
			</div>

			<div className='container'>
				<div className='section-ebook'>
					<div className='ebook-container'>
						<div className='ebook-text'>
							<h2 className='ebook-title'>eBook: As etapas da greve dos servidores públicos</h2>
							<p className='ebook-description'>
								O InfoGreve preparou este e-book especialmente para os dirigentes sindicais entenderem melhor sobre o
								direito de greve dos servidores públicos.
							</p>

							<button
								className='button button--featured'
								
							>

								<a href="https://conteudo.servidor.adv.br/ebook-as-etapas-da-greve-dos-servidores-publicos" target='_blank' style={{color:'#FFFFFF'}}>Baixar ebook</a>
							</button>
						</div>
						<div className='ebook-img'></div>
					</div>
				</div>
			</div>
			<div className='container'>
				<div className='section-container panfleto'>
					<div className='panfleto-container'>
						<div className='panfleto-title'>
							<div className='iconPanfleto'>
								<RiBookReadLine size={64} />
							</div>
							<h2>Panfleto InfoGreve</h2>
						</div>
						<div className='panfleto-info'>
							<p>
								Download do Panfleto InfoGreve, versões para Impressão doméstica, impressão em gráfica e para divulgação
								via e-mail, redes sociais e blogs
							</p>
							<button
								className='button button--featured'
								onClick={() => {
									downloadFile();
									setModeloID(4);
								}}
							>
								Baixar panfleto
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className='container'>
				<div className='section-container'>
					<div className='tag-title' style={{ marginTop: '-20px' }}>
						<span>Modelos</span>
					</div>
					<div className='modelos-section'>
						<div className='lista-modelos'>
							{modelos.map(modelo => (
								<div className='modelo-container' key={modelo.ID}>
									<button
										onClick={() => {
											handleSendModel();
											setModeloID(modelo.ID);
										}}
									>
										<div className='modelo-title'>{modelo.Title}</div>
										<RiDownload2Line size={20} />
									</button>
								</div>
							))}
						</div>
						<div className='img-modelos'>
							<img src='https://www.servidor.adv.br/images/working.jpg' alt='' />
						</div>
					</div>
				</div>
			</div>

			<hr />
			<div className='container'>
				<QueDizLei />
			</div>
			<hr />

			<div className='container'>
				<div className='section-container texto-etapas'>
					<div className='tag-title'>
						<span>etapas da greve</span>
					</div>
					<p>
						Saiba o que acontece em cada fase
						<br />
						As greves possuem uma ordem cronológica e você precisa saber o que acontece em cada uma dessas etapas. Além
						dessas informações, nessa seção você também poderá baixar os modelos de documentos que são imprescindíveis
						no processo.
					</p>
				</div>
			</div>

			<div className='container'>
				<div className='timeline-home'>
					<div className='timeline-item'>
						<div></div>

						<Link to={'/etapasdagreve'} className='titulo timeline-item-1'>
							Reinvidicação
						</Link>

						<div className='texto'>
							<p>
								Fase em que servidores externam para a Administração seus desejos e insatisfações relativas às condições
								de trabalho, oportunidade em que buscam negociar o atendimento de suas reivindicações
							</p>
						</div>
					</div>
					<div className='timeline-item'>
						<div className='texto'>
							<p>
								Fase que sucede o não atendimento das reivindicações dos servidores, em que a categoria insatisfeita
								delibera pela suspensão coletiva dos serviços
							</p>
						</div>

						<Link to={'/etapasdagreve'} className='titulo timeline-item-2'>
							Deflagração
						</Link>

						<div></div>
					</div>
					<div className='timeline-item'>
						<div></div>

						<Link to={'/etapasdagreve'} className='titulo timeline-item-3'>
							Condução
						</Link>

						<div className='texto'>
							<p>
								Fase em que os grevistas, representados pelo seu sindicato, utilizam de estratégias de manifestação e
								persuasão para incentivar a adesão dos servidores bem como para negociar com a Administração o
								atendimento das reivindicações
							</p>
						</div>
					</div>
					<div className='timeline-item'>
						<div className='texto'>
							<p>
								Fase em que a categoria delibera pelo retorno dos serviços, comumente ocasionada pelo sucesso das
								negociações com a Administração.
							</p>
						</div>
						<Link to={'/etapasdagreve'} className='titulo timeline-item-4'>
							Encerramento
						</Link>
					</div>
				</div>
			</div>

			<div className='container'>
				<div className='section-container section-iniciativas'>
					<div className='tag-title'>
						<span>Conheça nossas iniciativas</span>
					</div>
					<div className='iniciativas'>
						{iniciativas.map(iniciativa =>
							iniciativa.href !== null && iniciativa.href !== undefined ? (
								<a
									key={iniciativa.refID}
									className='iniciativa'
									href={iniciativa.href}
									target='_blank'
									rel='noopener noreferrer'
								>
									<img src={iniciativa.img} alt='' />
								</a>
							) : (
								<div
									key={iniciativa.refID}
									className='iniciativa'
									onClick={() => {
										downloadFile();
										setModeloID(iniciativa.refID);
									}}
								>
									<img src={iniciativa.img} alt='' />
								</div>
							)
						)}
					</div>
				</div>
			</div>
      
			<div className='container'>
				<div className='section-container section-contact-form'>
					<h3 className='form-title'>Estamos aqui para te ajudar</h3>
					<form onSubmit={handleContact}>
						<div className='form-group'>
							<label htmlFor=''>Nome</label>
							<input type='text' onChange={e => setNome(e.target.value)} required />
						</div>
						<div className='form-group'>
							<label htmlFor=''>Email</label>
							<input type='text' onChange={e => setEmail(e.target.value)} required />
						</div>
						<div className='form-group'>
							<label htmlFor=''>Celular</label>
							<input type='text' onChange={e => setTelefone(e.target.value)} />
						</div>
						<div className='form-group'>
							<label htmlFor=''>Perfil</label>
							<select onChange={e => MudarLabelFormContato(e)}>
								{options.map(option => (
									<option key={option.value} value={option.value}>
										{option.title}
									</option>
								))}
							</select>
						</div>
						<div className='form-group'>
							<label htmlFor='' id='labelComplemento'>
								Nome do órgão em que trabalha
							</label>
							<input type='text' onChange={e => setComplemento(e.target.value)} />
						</div>
						<div className='form-group'>
							<label htmlFor=''>Mensagem</label>
							<textarea name='' id='' rows='5' onChange={e => setMensagem(e.target.value)}></textarea>
						</div>
						<div className="form-group">
							<input type="checkbox" required id="acceptcheck"
								style={{height: 'initial', width: 'initial', padding: 0, marginRight: '5px'}} />
							<label for="acceptcheck">Eu concordo em receber comunicações.</label>
							<p style={{fontSize:'0.8em'}}>Ao informar meus dados, eu concordo que eles sejam tratados, de acordo com as regras da LGPD. Para mais informações, acesse na nossa <a href="https://conteudo.servidor.adv.br/politica-de-protecao-de-dados" target='_blank'>Política de Privacidade.</a></p>
						</div>
						<div className='button-wrapper'>
							<button className='button button--featured' type='submit'>
								Enviar mensagem
							</button>
						</div>
					</form>
				</div>
			</div>

			<Modal isShowing={isShowing} hide={toggle} type={type} itemID={modeloID} />
		</>
	);
}
