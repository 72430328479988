import React from 'react';
import './styles/general.scss';
import Routes from './routes';

function App() {
	return (
		<div>
			<Routes />
		</div>
	);
}

export default App;
