import React, { useEffect, useState, useCallback, useRef } from 'react';
import './styles.scss';
import { IoIosSearch } from 'react-icons/io';
import PostsList from '../../components/PostList';
import { getData } from '../../services/api';
import { SkeletonPostList } from '../../components/Skeleton';

export default function Noticias() {
	const [load, setLoad] = useState(true);
	const [items, setItems] = useState([]);

	const [busca, setBusca] = useState([]);
	const [paginationFilter, setPaginationFilter] = useState([]);

	const [topicos, setTopicos] = useState([]);
	const [topicoSelecionado, setTopicoSelecionado] = useState([]);
	const [hasMore, setHasMore] = useState(false);

	const observer = useRef();
	const lastItem = useCallback(
		node => {
			if (load) return;

			if (observer.current) observer.current.disconnect();

			observer.current = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting && hasMore) {
					setLoad(true);
					infinitePagination(paginationFilter);
				}
			});

			if (node) observer.current.observe(node);
		},

		[load, hasMore, paginationFilter]
	);

	const mudarValorDropdown = () => {
		setTopicoSelecionado(window.location.href.split('/').pop());
		document.querySelector('#topicoRelacionado').value = window.location.href.split('/').pop();
	};

	useEffect(() => {
		handleFilter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [topicoSelecionado]);

	useEffect(() => {
		if (window.location.href.split('/').pop() !== undefined && !isNaN(window.location.href.split('/').pop()) && topicos.length > 0) {
			mudarValorDropdown();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [topicos]);

	useEffect(() => {
		const buscarTopicos = async () => {
			const guid = '036fa973-befc-4b54-80f5-67b0cd3db545';
			const itemsFilter = 'items?$top=5000';
			const res = await getData(guid, itemsFilter);

			setTopicos(res.data);
		};

		const buscarNoticias = async () => {
			const guid = '24075851-6373-4890-9665-807bdc713906';
			const itemsFilter =
				!isNaN(parseFloat(window.location.href.split('/').pop())) &&
				window.location.href.split('/').pop() !== undefined
					? `items?&$orderby=DataPublicacao desc&$skiptoken=Paged=TRUE&$filter=TopicosId eq ${window.location.href.split('/').pop()}&$top=10`
					: 'items?&$orderby=DataPublicacao desc&$skiptoken=Paged=TRUE&$top=10';

			const paginationFilter =
				!isNaN(parseFloat(window.location.href.split('/').pop())) &&
				window.location.href.split('/').pop() !== undefined
					? `$filter=TopicosId eq ${window.location.href.split('/').pop()}`
          : '';

			setPaginationFilter(paginationFilter);

			const res = await getData(guid, itemsFilter);

			setHasMore(res.data.length > 0);
      setItems(res.data);      
			setLoad(false);
		};

		buscarTopicos();
		buscarNoticias();
	}, []);

	//filtro campo de busca
	const handleFilter = async () => {
		const guid = '24075851-6373-4890-9665-807bdc713906';

		function categoryPagination(search) {

      if (search.length === 0 && topicoSelecionado.length === 0)
        return '';

			if (search.length === 0 && topicoSelecionado.length !== 0)
				return `$filter=TopicosId eq ${topicoSelecionado}`;

			if (search.length > 0 && topicoSelecionado.length === 0)
				return `$filter=substringof('${busca}', Title)`;

			if (search.length > 0 && topicoSelecionado.length !== 0)
				return `$filter=(substringof('${busca}', Title) and TopicosId eq ${topicoSelecionado})`;
		}

		const itemsFilter = categoryPagination(busca);
		const paginationFilter = `items?&$orderby=DataPublicacao desc&${itemsFilter}`;

    console.log(itemsFilter);
    
		setPaginationFilter(paginationFilter);

		async function filterResults() {
			setLoad(true);
			const res = await getData(guid, paginationFilter);

			setItems(res.data);
			setHasMore(res.data.length > 0);
			setLoad(false);
		}

		filterResults();
	};

	function infinitePagination(filter) {
		const guid = '24075851-6373-4890-9665-807bdc713906';
		const itemsContainer = document.querySelector('.resultados-container');
		const dateOfLastItem =
			itemsContainer !== null && itemsContainer !== undefined && itemsContainer.hasChildNodes()
				? itemsContainer.lastElementChild.querySelector('.data-publicacao').textContent.split(': ')[1]
				: '';

    const filteredDate = new Date(`${dateOfLastItem.split('/')[1]} ${dateOfLastItem.split('/')[0]} ${dateOfLastItem.split('/')[2]}`).toISOString();
        
		async function pagination() {
			const itemsFilter =
				filter.length === 0
					? `items?&$orderby=DataPublicacao desc&$skiptoken=Paged=TRUE&$filter=DataPublicacao lt '${filteredDate}'&$top=10`
					: `items?&$orderby=DataPublicacao desc&$skiptoken=Paged=TRUE&${filter} and DataPublicacao lt '${filteredDate}'&$top=10`;      

			const res = await getData(guid, itemsFilter);

			setItems(previousItems => {
				return [...new Set([...previousItems, ...res.data])];
			});

			setHasMore(res.data.length > 0);

			setLoad(false);
		}

		pagination(filter);
	}

	return (
		<div className='container'>
			<div className='filtros-noticias'>
				<div className='item-fn busca'>
					<label htmlFor='search'>Pesquisar notícias</label>
					<div className='container-filtro-busca'>
						<input
							type='text'
							id='search'
							onChange={e => setBusca(e.target.value)}
							onKeyPress={e => (e.key === 'Enter' ? handleFilter() : '')}
						/>
						<button
							onClick={e => {
								e.preventDefault();
								handleFilter();
							}}
						>
							<IoIosSearch size={20} />
						</button>
					</div>
				</div>
				<div className='item-fn topico-filtro'>
					<label htmlFor=''>Topico relacionado</label>
					<select
						name=''
						id='topicoRelacionado'
						onChange={e => {
							setTopicoSelecionado(e.target.value);
							window.history.pushState('', '', `#/noticias/${e.target.value}`);
						}}
					>
						<option value=''>Selecione</option>
						{topicos.map(topico => (
							<option value={topico.ID} key={topico.ID}>
								{topico.Title}
							</option>
						))}
					</select>
				</div>
			</div>
			<div className='resultados-container'>
				{items.map((item, index) => {
					const lastIndex = index.toString().slice(-1);
					const last = parseInt(lastIndex);

					if (items.length === index + 1) {
						return (
							<PostsList
								key={item.ID}
								pageName={'noticia'}
								post={item}
								animationOrder={last}
								reference={lastItem}
							/>
						);
					} else {
						return (
							<PostsList
								key={item.ID}
								pageName={'noticia'}
								post={item}
								loading={load}
								animationOrder={last}
							/>
						);
					}
				})}
			</div>
			{load === true ? (
				<div className='load-container'>
					{[...Array(5)].map((x, i) => (
						<SkeletonPostList key={i} />
					))}
				</div>
			) : (
				''
			)}
		</div>
	);
}
