import React, { useState, useEffect } from 'react';
import './styles.scss';
import { getData } from '../../services/api';
import { SkeletonPostContent } from '../Skeleton';
import { sendEmailServidor, addPersonToPipe, getDataGeral } from '../../services/api';

export default function PostContent({ post, loading }) {
	const [nome, setNome] = useState([]);
	const [email, setEmail] = useState([]);
	const [duvida, setDuvida] = useState([]);
	const [pagina, setPagina] = useState([]);
	const [authors, setAuthors] = useState([]);
	const [topicos, setTopicos] = useState([]);

	useEffect(() => {
		const getAuthors = async () => {
			const filter = 'items';
			const res = await getDataGeral(filter);

			setAuthors(res.data);
		};

		const buscarTopicos = async () => {
			const guid = '036fa973-befc-4b54-80f5-67b0cd3db545';
			const res = await getData(guid, 'items');

			if (res.data.length === 0) return [];

			setTopicos(res.data);
		};

		getAuthors();
		buscarTopicos();
	}, []);

	if (loading) return <SkeletonPostContent rows={4} />;

	function htmlToText(html) {
		const doc = new DOMParser().parseFromString(html, 'text/html');
		if (doc.body.querySelectorAll('p').length > 0) return doc.body.querySelectorAll('p');
		else return doc.body.querySelectorAll('div');
	}

	const EnviarFormDuvida = async e => {
		e.preventDefault();

		const formData = `<p>Nome: ${nome}</p>
       <p>Email: ${email}</p>
       <p>Titulo do Post: ${document.querySelector('.post-title').textContent}</p>
       <p>Duvida: ${duvida}</p>`;

		const res = await sendEmailServidor(formData, pagina);
		await addPersonToPipe(nome, '', email);

		if (res.statusRes === 202) clearFields();
	};

	const clearFields = () => {
		alert('Formulário de contato enviado com sucesso!');

		setNome('');
		setEmail('');
		setDuvida('');
		setPagina('');

		document.querySelectorAll('input').forEach(input => (input.value = ''));
		document.querySelectorAll('textarea').forEach(textarea => (textarea.value = ''));
	};

	const acceptTerms = () => {
		return(
			<div className="form-group">
				<input type="checkbox" required id="acceptcheck"
					style={{height: 'initial', width: 'initial', padding: 0, marginRight: '5px'}} />
				<label for="acceptcheck">Eu concordo em receber comunicações.</label>
				<p style={{fontSize:'0.8em'}}>Ao informar meus dados, eu concordo que eles sejam tratados, de acordo com as regras da LGPD. Para mais informações, acesse na nossa <a href="https://conteudo.servidor.adv.br/politica-de-protecao-de-dados" target='_blank'>Política de Privacidade.</a></p>
			</div>
		);
	};

	return (
		<div className='post-text'>
			{console.log(post)}
			{post.map(item => (
				<div className='post-content' key={item.ID}>
					<div className='post-title'>
						{window.location.href.includes('noticia') ? (
							<>
								<h1 className='titulo-noticia'>{item.Title}</h1>
								<div className='data-publicacao'>
									Data de publicação:{' '}
									{new Date(item.DataPublicacao).toLocaleDateString('pt-BR')}
								</div>
								<div className='topicos-relacionados-noticia'>
									{topicos.map(topico =>
										item.TopicosId !== undefined && item.TopicosId.results[0] === topico.ID ? (
											<span key={topico.ID}>{topico.Title}</span>
										) : (
											''
										)
									)}
								</div>
							</>
						) : (
							<h1>{item.Title}</h1>
						)}

						{item.AutoresId !== null && authors.length > 0 ? (
							<div className='autor'>
								<span>Por: {authors.filter(x => x.ID === item.AutoresId)[0].FullName}</span>
							</div>
						) : (
							''
						)}
					</div>
					{(window.location.href.includes('oquedizalei') && item.Texto !== undefined) ||
					(window.location.href.includes('topico') && item.Texto !== undefined) ? (
						<>
							{console.log(item)}
							{Array.from(htmlToText(item.Texto)).map((el, index) => (
								<p className='post-p' key={index}>
									{el.textContent}
								</p>
							))}
						</>
					) : (
						''
					)}

					{window.location.href.includes('topico') ? (
						<form className='form-duvida' onSubmit={EnviarFormDuvida}>
							<div className='form-duvida-title'>Saiba mais</div>
							<div className='info'>
								<div className='form-group'>
									<label htmlFor='nome'>Nome</label>
									<input
										type='text'
										id='nome'
										onChange={e => setNome(e.target.value)}
										required
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='email'>Email</label>
									<input
										type='email'
										id='email'
										onChange={e => setEmail(e.target.value)}
										required
									/>
								</div>
							</div>
							<div className='form-group'>
								<label htmlFor='duvida'>Duvida</label>
								<textarea rows='5' id='duvida' onChange={e => setDuvida(e.target.value)} />
							</div>
							{acceptTerms()}
							<div className='action-button'>
								<button
									type='submit'
									className='button button--featured'
									onClick={() => setPagina('Topicos')}
								>
									Enviar
								</button>
							</div>
						</form>
					) : (
						''
					)}
					{window.location.href.includes('noticia') &&
					item.Body !== null &&
					item.Body !== undefined ? (
						<>
							{Array.from(htmlToText(item.Body)).map((el, index) => (
								<p className='post-p' key={index}>
									{el.textContent}
								</p>
							))}
							{item.Fonte !== undefined && item.Fonte !== null ? (
								<ul className='fonte-list'>
									<li>Fonte:</li>
									<li>
										{item.LinkdaNoticiaOriginal !== null &&
										item.LinkdaNoticiaOriginal !== undefined ? (
											<a
												href={item.LinkdaNoticiaOriginal.Url}
												target='_blank'
												rel='noopener noreferrer'
											>
												{item.Fonte}
											</a>
										) : (
											item.Fonte
										)}
									</li>
								</ul>
							) : (
								''
							)}
						</>
					) : (
						''
					)}

					{window.location.href.includes('pergunta') &&
					item.Resposta !== null &&
					item.Resposta !== undefined ? (
						<div style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
							{Array.from(htmlToText(item.Resposta)).map((el, index) => (
								<p className='post-p' key={index}>
									{el.textContent}
								</p>
							))}
							<form className='form-duvida' onSubmit={EnviarFormDuvida}>
								<div className='form-duvida-title'>Tem alguma dúvida? Fale conosco</div>
								<div className='info'>
									<div className='form-group'>
										<label htmlFor='nome'>Nome</label>
										<input
											type='text'
											id='nome'
											onChange={e => setNome(e.target.value)}
											required
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='email'>Email</label>
										<input
											type='email'
											id='email'
											onChange={e => setEmail(e.target.value)}
											required
										/>
									</div>
								</div>
								<div className='form-group'>
									<label htmlFor='duvida'>Tem alguma duvida?</label>
									<textarea
										rows='5'
										id='duvida'
										onChange={e => setDuvida(e.target.value)}
									/>
								</div>
								{acceptTerms()}
								<div className='action-button'>
									<button
										type='submit'
										className='button button--featured'
										onClick={() => setPagina('Perguntas e Respostas')}
									>
										Enviar
									</button>
								</div>
							</form>
						</div>
					) : (
						''
					)}
				</div>
			))}
		</div>
	);
}
