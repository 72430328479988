import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';

export default function PostsList({ post, pageName, reference, animationOrder }) {
	function htmlToText(html) {
		const text = [];
		const item = new DOMParser().parseFromString(html, 'text/html');

		if (item.body.querySelectorAll('p').length > 0) {
			item.body.querySelectorAll('p').forEach(item => {
				text.push(item);
			});
		} else {
			item.body.querySelectorAll('div').forEach(item => {
				text.push(item);
			});
		}

		return text;
	}

	return (
		<Link
			to={`/post/${pageName}/?item=${post.ID}`}
			className='item-lp'
			id={post.ID}
			ref={reference}
			style={{ '--animation-order': animationOrder }}
		>
			<div className='item-info'>
				<h3 className='item-title'>{post.Title}</h3>
				{post.Tags !== undefined && post.Tags !== null ? (
					<ul className='tags'>
						{post.Tags.map(tag => (
							<li>{tag}</li>
						))}
					</ul>
				) : (
					''
				)}
				<div className='item-short-text'>
					{post.Body !== undefined && post.Body !== null
						? Array.from(htmlToText(post.Body)).map((el, index) => (
								<div className='short-text' key={index}>
									{el.textContent}
								</div>
						  ))
						: Array.from(htmlToText(post.Texto)).map((el, index) => (
								<p className='short-text' key={index}>
									{el.textContent}
								</p>
						  ))}
				</div>
				{post.DataPublicacao !== undefined ? (
					<div className='data-publicacao'>
						Data de publicação: {new Date(post.DataPublicacao).toLocaleDateString('pt-BR')}
					</div>
				) : (
					''
				)}
			</div>
		</Link>
	);
}
