import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import { RiAlarmWarningLine, RiMenuLine, RiHome2Line } from 'react-icons/ri';
import Modal from '../Modal';
import useModal from '../../hooks/useModal';
import Cookiebar from '../Cookiebar/index.js';

export default function Navbar({ logo, logoAlt, links }) {
	const { isShowing, toggle } = useModal();
	const [type, setType] = useState([]);
	const [navOpen, setNavOpen] = useState(false);

	function handleFaleConosco() {
		setType('fale_conosco');
		setNavOpen(false);
		toggle();
	}

	function handleEmergencia() {
		setType('emergencia');
		setNavOpen(false);
		toggle();
	}

	return (
		<>
			<nav className={navOpen ? 'navbar open' : 'navbar'}>
			
				<div className='nav-container'>
					<Link to='/'>
						<img src={logo} alt={logoAlt} />
					</Link>

					<button className='hamburger-menu' onClick={() => setNavOpen(!navOpen)}>
						<RiMenuLine size={24} />
					</button>

					<div className='links-container'>
						<Link to='/'>
							<RiHome2Line size={24} />
						</Link>
                  
						{Array.from(links).map((link, index) => (
							<Link to={`/${link.linkTo}`} key={index} onClick={() => setNavOpen(false)}>
								{link.linkTitle}
							</Link>
						))}
						<button onClick={handleFaleConosco}>Fale conosco</button>
						<button onClick={handleEmergencia}>
							<RiAlarmWarningLine size={24} />
							Emergência
						</button>
					</div>
				</div>
				{/* <Cookiebar/> */}
			</nav>

			<Modal isShowing={isShowing} hide={toggle} type={type} itemID={''} />
		</>
	);
}
