import axios from 'axios';

async function getData(guid, filters) {
	const res = await axios.post(
		'https://prod-108.westus.logic.azure.com:443/workflows/0434314b28554bcba0fbfcea52164845/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=cGTyEoCerrkoDsKII3pf1R6m54c6xLXlwCQWy1g_sEw',
		{
			listId: guid,
			filter: filters,
		}
	);

	const data = res.data.d.results;

	return { data };
}

async function getDataGeral(filter) {
	const res = await axios.post(
		'https://prod-40.westus.logic.azure.com:443/workflows/e3d688cf525f4832abc71d9da6680a5d/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=HQ20FmRbjC-lEkqDi2-XLq2pEP2e8qh8nzy8S6UMuCs',
		{
			filter: `_api/web/lists(guid'90c0948c-4728-4cee-95ec-3bc14a3cf7e3')/${filter}`,
		}
	);

	const data = res.data.d.results;

	return { data };
}

async function sendEmail(body) {
	const res = await axios.post(
		'https://prod-141.westus.logic.azure.com:443/workflows/4af3fecf212f4b5c9dbf77e4fe1b5bbd/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=wWWcqjKf3v8VTc4GTOFTX9UR3RBgToJbQfVH6ts-n28',
		{
			body: body,
		}
	);

	const statusRes = res.status;

	return { statusRes };
}

async function sendEmailServidor(body, page) {
	const res = await axios.post(
		'https://prod-35.westus.logic.azure.com:443/workflows/2c48cf73bc224943b6a980c2f05ede6a/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=uP9mFdas1eZutcyAXYDY1a_p4TF6hjplVNAr4WnzNC0',
		{
			body: body,
			page: page,
		}
	);

	const statusRes = res.status;

	return { statusRes };
}

async function sendModel(itemID, to) {
	const res = await axios.post(
		'https://prod-29.westus.logic.azure.com:443/workflows/437baac6df3240b981c2ceed81d4fe44/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=0TruX-J4ca50vDiOnouFfUge8DjmbsmcfqsQipgirhk',
		{
			ID: itemID,
			Email: to,
			Lista: 'Modelos - InfoGreve'
		}
	);

	const statusRes = res.status;

	return { statusRes };
}

async function sendMaterial(itemID, to) {
	const res = await axios.post(
		'https://prod-29.westus.logic.azure.com:443/workflows/437baac6df3240b981c2ceed81d4fe44/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=0TruX-J4ca50vDiOnouFfUge8DjmbsmcfqsQipgirhk',
		{
			ID: itemID,
			Email: to,
			Lista: 'Materiais Educativos'
		}
	);

	const statusRes = res.status;

	return { statusRes };
}

const addPersonToPipe = async (name, phone, email) => {
	const searchByEmail = () =>
		axios
			.get(
				`https://apisiteservidoradv.azurewebsites.net/api/pipedrive/persons/searchid?term=${email}&fields=email`
			)
			.then(res => {
				return res.data;
			});

	const validation = await searchByEmail();

	function addPerson() {
		validation.success
			? addToPipe()
			: axios
					.post(`https://apisiteservidoradv.azurewebsites.net/api/pipedrive/persons`, {
						name: name,
						phone: phone,
						email: email,
					})
					.then(() => {
						searchByEmail();
					})
					.then(() => {
						addPerson();
					});
	}

	function addToPipe() {
		axios.post(`https://apisiteservidoradv.azurewebsites.net/api/pipedrive/deals`, {
			title: `Negocio ${name}`,
			personId: validation.personId,
			pipelineId: 14,
		});
	}

	addPerson();
};

export {
	getData,
	getDataGeral,
	sendEmail,
	sendModel,
	sendMaterial,
	addPersonToPipe,
	sendEmailServidor,
};
